import React, { useState } from 'react'
import {
    ExitToAppOutlined,
    LayersOutlined,
    PaymentsOutlined,
    PersonOutline,
    SettingsOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Drawer,
    Grid,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthPopUpValues } from "../../../Services/Auth/AuthSlice";
import { setLayoutPopUPValues } from "../../../Services/Student/Layout/LayoutSlice";
import { useGetBalanceQuery } from "../../../api/Student/accountApi";
import Gift from "../../../assets/gift.svg"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CoinImage from "../../../assets/image.svg"

const CoinMenu = () => {
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const {
        popUps: { coinMenu },
    } = useSelector((state) => state.layoutStudent);
    const closeCoinMenu = () => {
        dispatch(setLayoutPopUPValues({ key: "coinMenu", value: false }));
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useSelector((store) => store.auth);
    const { data: coins } = useGetBalanceQuery(
        {},
        {
            skip: isAuthenticated ? false : true,
        }
    );

    const content = (
        <Box sx={{ paddingBottom: !mdUp ? "56px" : "" }}>
            <Box>
                <Box sx={{ p: "10px 16px 16px 16px", bgcolor: "#EFF6FF", textAlign: "center" }}>
                    <img src={CoinImage} alt="CoinImage" width="94px" height="80px" />
                    <Typography color="#737373" fontSize="14px" fontWeight={500} >Всего вы накопили</Typography>
                    <Typography color="#262626" fontSize="28px" fontWeight={700} >{coins?.student_courses_with_coins[0]?.student_course_coin} бонусов</Typography>
                </Box>
                <Box sx={{
                    bgcolor: "#F3F4F6",
                    p: "6px 8px 6px 6px",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    my: "12px",
                    width: "172px",
                    fontSize: "14px",
                    mx: "auto"
                }} >
                    <InfoOutlinedIcon color='#737373' />
                    1 бонус - {coins?.exchange_rate?.one_coin_vs_time[1]}
                </Box>
            </Box>
            <Box sx={{ p: "4px", bgcolor: "#F8FAFC", borderRadius: "12px", display: "flex", flexDirection: "column", rowGap: "4px" }}>
                {coins?.student_courses_with_coins?.map((elem) => {
                    return (
                        <MenuItem
                            sx={{
                                padding: "12px",
                                borderRadius: "8px",
                                backgroundColor: "white",
                                fontWeight: "600",
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                "&:hover": {
                                    bgcolor: "white"
                                }
                            }}
                        >
                            <Typography fontSize={"14px"} fontWeight={"600"} color="#262626" noWrap sx={{ width: "150px"}}>
                                {elem?.name}
                            </Typography>
                            <Box
                                sx={{ width: "100px", p: "6px 8px", display: "flex", gap: "4px", bgcolor: "#EFF6FF", borderRadius: "100px", cursor: "pointer" }} >
                                <img src={Gift} alt="Gift icon" />
                                <Typography noWrap color="#2563EB" sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px" }} > {elem?.student_course_coin} б</Typography>
                            </Box>
                        </MenuItem>
                    )
                })}
            </Box>
        </Box>
    );

    if (!mdUp) {
        return (
            <Drawer
                anchor="bottom"
                open={coinMenu}
                onClose={closeCoinMenu}
                PaperProps={{
                    sx: {
                        backgroundColor: "#F8F9FB",
                        // color: "common.white",
                        // width: 250,
                        height: "70%",
                        borderTopRightRadius: "16px",
                        borderTopLeftRadius: "16px",
                        padding: "12px 20px 28px 20px",

                    },
                }}
                sx={{ zIndex: "10000g" }}
            // variant="permanent"
            >
                {content}
            </Drawer>
        );
    }
    return (
        <>
            <Menu
                // disableScrollLock={true}
                anchorEl={coinMenu}
                id="account-menu"
                open={coinMenu}
                onClose={closeCoinMenu}
                // onClick={closeProfileMenu}
                // disableScrollLock={true}
                // sx={{ position: "absolute", top: "50px", left: "-200px", width: "320px" }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: { xs: "scroll", sm: "auto" },
                        width: "320px",
                        // boxShadow: "none",
                        boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                        filter: "none",
                        backgroundColor: "#FFF",
                        borderRadius: "16px",
                        padding: "20px",
                        border: "1px solid #E2E8F0",
                        mt: "53px",
                        // "& .MuiAvatar-root": {
                        //   width: 32,
                        //   height: 32,
                        //   ml: -0.5,
                        //   mr: 1,
                        // },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            backgroundColor: "#F8F9FB",
                            transform: "translateX(-50%) rotate(45deg)",
                            zIndex: 0,
                            boxShadow: "none",
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                {content}
            </Menu>
        </>
    )
}

export default CoinMenu