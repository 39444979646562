import React from "react";
import {
  ExitToAppOutlined,
  LayersOutlined,
  PaymentsOutlined,
  PersonOutline,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthPopUpValues } from "../../../Services/Auth/AuthSlice";
import { setLayoutPopUPValues } from "../../../Services/Student/Layout/LayoutSlice";
import { useGetBalanceQuery } from "../../../api/Student/accountApi";

const ProfileMenu = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { user, isAuthenticated } = useSelector((store) => store.auth);
  const {
    popUps: { menuAnchor },
  } = useSelector((state) => state.layoutStudent);
  const { data } = useGetBalanceQuery(
    {},
    {
      skip: isAuthenticated ? false : true,
    }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeProfileMenu = () => {
    dispatch(setLayoutPopUPValues({ key: "menuAnchor", value: null }));
  };
  const profileMenuLinks = [
    {
      id: 1,
      title: "Профили ман",
      icon: <PersonOutline />,
      path: "/account/",
    },
    {
      id: 2,
      title: "Танзимотҳо",
      icon: <SettingsOutlined />,
      path: "/account/settings",
    },
    {
      id: 3,
      title: "Харидҳо",
      icon: <LayersOutlined />,
      path: "/account/purchases",
    },
  ];

  const content = (
    <Box sx={{ paddingBottom: !mdUp ? "56px" : "" }}>
      <Box
        sx={{
          padding: "12px 8px",
          display: "flex",
          gap: "16px",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#CBD5E1",
            borderRadius: "10px",
            cursor: "pointer",
            width: "60px",
            height: "60px",
            color: "#262626",
            fontSize: "24px",
          }}
          variant="rounded"
          src={`${process.env.REACT_APP_ASSTES_URL}storage/${user?.photo}`}
        >
          {user?.name?.charAt(0)}
          {user?.surname?.charAt(0)}
        </Avatar>
        <Box>
          <Typography fontSize={"20px"} fontWeight={"600"}>
            {user?.name}
          </Typography>
          <Typography fontSize={"14px"} fontWeight={"400"} color={"#737373"}>
            {user?.email || user?.phone}
          </Typography>
        </Box>
      </Box>
      {/* <Grid container spacing={"12px"} mb={"12px"}> */}
        
        {/* <Grid item lg={6}> */}
          <Box
            sx={{
              padding: "16px",
              borderRadius: "16px",
              backgroundColor: "white",
              display: "flex",
              gap: "12px",
              transition: ".25s",
              mb:"12px",
              alignItems:"center"

            }}
            className="hoverEffect"
            onClick={() => {
              navigate("/account/wallet");
              closeProfileMenu();
            }}
          >
            <PaymentsOutlined color="primary" sx={{ fontSize: "24px" }} />
                <Typography flexGrow={1} fontWeight={"500"} fontSize={"14px"}>
                  Ҳамён
                </Typography>
            <Box
              sx={{
                padding: "7px 8px",
                borderRadius: "8px",
                backgroundColor: "#E2E8F0",
                // width: "max-content",
                display: "flex",
                flexWrap: "wrap",
                gap: "4px",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={"600"} fontSize={"14px"}>
                {data?.balance ? Math.trunc(data?.balance) + " c" : 0}
              </Typography>
            </Box>
          </Box>
        {/* </Grid> */}
      {/* </Grid> */}
      <Box
        sx={{
          padding: "4px",
          backgroundColor: "white",
          borderRadius: "16px",
          overflowX: "hidden",
          mb: "12px",
        }}
      >
        {profileMenuLinks.map((link) => {
          return (
            <MenuItem
              key={link.id}
              sx={{
                padding: "12px 20px",
                borderRadius: "10px",
                backgroundColor: "white",
                fontSize: "16px",
                fontWeight: "600",
                color: "#2563EB",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => {
                navigate(link.path);
                closeProfileMenu();
              }}
            >
              {link.icon}
              <Typography fontSize={"16px"} fontWeight={"500"}>
                {link.title}
              </Typography>
            </MenuItem>
          );
        })}
      </Box>
      <MenuItem
        sx={{
          padding: "12px 20px",
          borderRadius: "10px",
          backgroundColor: "white",
          fontSize: "16px",
          fontWeight: "600",
          color: "#EF4444",
          display: "flex",
          gap: "8px",
          alignItems: "center",
          width: "100%",
        }}
        onClick={() => {
          //   navigate(link.path);
          dispatch(setAuthPopUpValues({ key: "logOutModal", value: true }));
          closeProfileMenu();
        }}
      >
        <ExitToAppOutlined />
        <Typography fontSize={"16px"} fontWeight={"500"} color="#EF4444">
          Выйти
        </Typography>
      </MenuItem>
    </Box>
  );

  if (!mdUp) {
    return (
      <Drawer
        anchor="bottom"
        open={menuAnchor}
        onClose={closeProfileMenu}
        PaperProps={{
          sx: {
            backgroundColor: "#F8F9FB",
            // color: "common.white",
            // width: 250,
            height: "70%",
            borderTopRightRadius: "16px",
            borderTopLeftRadius:"16px",
            padding:"12px 20px 28px 20px",
           
          },
        }}
        sx={{  zIndex: "10000g"}}
        // variant="permanent"
      >
        {content}
      </Drawer>
    );
  }
  return (
    <Menu
      // disableScrollLock={true}
      anchorEl={menuAnchor}
      id="account-menu"
      open={!!menuAnchor}
      onClose={closeProfileMenu}
      // onClick={closeProfileMenu}
      // disableScrollLock={true}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: { xs: "scroll", sm: "auto" },
          width: "300px",
          // boxShadow: "none",
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
          filter: "none",
          backgroundColor: "#F8F9FB",
          borderRadius: "16px",
          padding: "12px 20px 28px 20px",
          border: "1px solid #E2E8F0",
          mt: 1.5,
          // "& .MuiAvatar-root": {
          //   width: 32,
          //   height: 32,
          //   ml: -0.5,
          //   mr: 1,
          // },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "#F8F9FB",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            boxShadow: "none",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {content}
    </Menu>
  );
};

export default ProfileMenu;
