import {
  useMediaQuery
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LogoWhite from "../../assets/Logo_white.svg";
import style from "./Layout.module.css";
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../api/loginApi";
import facebookIcon from "../../assets/facebookIcon.svg";
import instagramIcon from "../../assets/instagramIcon.svg";
import mailIcon from "../../assets/mail.svg";
import phoneIcon from "../../assets/phone.svg";
import telegramIcon from "../../assets/telegrammIcon.svg";
import LogOutModal from "../../components/Student/LogOutModal";
import StudySidebar from "../../components/Student/Study/StudySidebar/StudySidebar";
// import StudySidebarTel from "../../components/Student/StudySidebarTel/StudySidebarTel";
import {
  setAuthPopUpValues
} from "../../Services/Auth/AuthSlice";
import { setLayoutPopUPValues } from "../../Services/Student/Layout/LayoutSlice";
import Navbar from "../../components/Student/Layout/Navbar";
import { destroyToken, getToken } from "../../utils/axiosRequest";
import { useGetMeQuery } from "../../api/authApi";

const Layout = () => {
  const [logout, { isLoading: logOutLoading }] = useLogoutMutation();
  const {
    popUps: { sidebar, navbar, studySidebar, menuAnchor },
  } = useSelector((state) => state.layoutStudent);
  const {
    popUps: { logOutModal },
  } = useSelector((store) => store.auth);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [user, setUser] = useState(false);
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const purchased = useSelector(({ purchases }) => purchases.purchases);
  const topics = useSelector(({ purchases }) => purchases.topics);



  const menuRef = useRef();
  const menuRef2 = useRef();

  const { data } = useGetMeQuery(getToken()?.user_type == 3 ? "student" : "");

  const handleLogout = async () => {
    try {
      const response = await logout().unwrap();
      navigate("/login");
      destroyToken();
      setUser(false);
      dispatch(setAuthPopUpValues({ key: "logOutModal", value: false }));
      // setLogOutModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className={`${style.main}`}>
        <Navbar />
        <div className={style.flex}>
          {/* {pathname.includes("login") ||
          pathname.includes("register") ? null : pathname.includes("study") ? (
            <>
              <StudySidebar
                studySidebar={studySidebar}
                closeSidebar={() =>
                  dispatch(
                    setLayoutPopUPValues({
                      key: "studySidebar",
                      value: !studySidebar,
                    })
                  )
                }
                courseID={purchased?.course?.id}
                name={purchased?.course?.name}
                lessons={purchased?.total_lessons}
                completeLessons={purchased?.completed_lessons}
                percentage={purchased?.progress_percentage}
                topics={topics}
              />
            </>
          ) :  null} */}
          <div className={`${style.left}`}>
            <div className={style.outlet}>
              <div
                className={
                  pathname.includes("study") && studySidebar == false
                    ? style.outletStudySidebarClose
                    : pathname.includes("study") && studySidebar == true
                      ? `${style.outletStudySidebarOpen}`
                      : null
                }
              >
                <Outlet />
              </div>
            </div>
            {pathname.includes("login") ||
              pathname.includes("register") ? null : (
              <>
                {" "}
                <div id="contacts" className={`${style.footer}`} style={{ paddingBottom: !mdUp ? "56px" : "" }} >
                  <div
                    className={
                      pathname.includes("study") && studySidebar == false
                        ? style.container
                        : pathname.includes("study") && studySidebar == true
                          ? style.containerStudySidebarOpen
                          : user == true
                            ? style.container2
                            : style.container
                    }
                  >
                    <div className={style.footerMain}>
                      <div className={style.footleft}>
                        <div className={style.footleftTop}>
                          <img
                            src={LogoWhite}
                            style={{ width: "140px" }}
                            className={style.logo}
                            alt=""
                          />
                          {/* <FormControl
                          //  sx={{ width }}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={lang}
                              onChange={(e) => setLang(e.target.value)}
                              sx={{
                                // width:"130px",
                                height: "48px",
                                borderRadius: "10px",
                                color: "white",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#3B82F6",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "white",
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "#3B82F6 !important",
                                },
                                // textAlign: "center",
                              }}
                            >
                              <MenuItem value={"eng"}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    px: "10px",
                                  }}
                                >
                                  <img src={eng} alt="" /> English
                                </Box>
                              </MenuItem>
                              <MenuItem value={"rus"}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    px: "10px",
                                  }}
                                >
                                  <img src={rus} alt="" /> Русский
                                </Box>
                              </MenuItem>
                              <MenuItem value={"tj"}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    px: "10px",
                                  }}
                                >
                                  <img src={tj} alt="" /> Точики
                                </Box>
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                        </div>
                        <h1>
                          Барномасозиро аз беҳтарин омӯзгорони Тоҷикистон ва
                          хориҷа омӯзед.
                        </h1>
                        <ul>
                          <a
                            href="https://www.facebook.com/OmuzOnline"
                            target="_blank"
                          >
                            <li>
                              <img src={facebookIcon} />
                            </li>
                          </a>
                          <a href="https://t.me/onlineomuztj" target="_blank">
                            <li>
                              <img src={telegramIcon} />
                            </li>
                          </a>
                          <a
                            href="https://www.instagram.com/online.omuz/"
                            target="_blank"
                          >
                            <li>
                              <img src={instagramIcon} />
                            </li>
                          </a>
                          {/* <li>
                            <img
                            src={whatsupIcon}
                            />
                          </li> */}
                        </ul>
                      </div>
                      <div className={style.footright}>
                        <ul>
                          <li>Маълумот</li>
                          <div className={style.footelLine}></div>
                          <li>
                            {" "}
                            <Link
                              to="/"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              Асосӣ
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/courses"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              {" "}
                              Курсҳо
                            </Link>
                          </li>
                          {/* <li>О нас</li> */}
                        </ul>
                        <ul>
                          <li>Тамос</li>
                          <div className={style.footelLine}></div>
                          <li>
                            <div className={style.iconText}>
                              <img src={phoneIcon} alt="" />{" "}
                              <a
                                href="tel://+992002223232"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                {" "}
                                (+992) 002 223 232 <br />
                                (+992) 111 585 050
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className={style.iconText}>
                              <img src={mailIcon} alt="" />{" "}
                              <a
                                href="mailto:online@omuz.tj"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                online@omuz.tj
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className={navbar ? style.searchMenuOpen : style.searchMenuClose}>
          <div ref={menuRef2} className={style.searchMenuMain}>
            <ul>
              <NavLink
                className={({ isActive }) =>
                  isActive ? style.headerLinkActive : null
                }
                to="/"
                style={{ textDecoration: "none" }}
              >
                <li>Асосӣ</li>
              </NavLink>
              {/* <li>О нас</li> */}
              {getToken()?.user_type == 3 ? null : (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? style.headerLinkActive : null
                  }
                  to="/courses"
                  style={{ textDecoration: "none" }}
                >
                  <li>Курсҳо</li>
                </NavLink>
              )}
              <NavLink
                className={({ isActive }) =>
                  isActive ? style.headerLinkActive : null
                }
                to="/contacts"
                style={{ textDecoration: "none" }}
              >
                <li>Тамос</li>
              </NavLink>
            </ul>
            {/* <TextField
              sx={{ width: "100%", height: "40px" }}
              InputProps={{
                style: {
                  borderRadius: "100px",
                  height: "40px",
                  paddingLeft: "10px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Найти курс..."
            /> */}
          </div>
        </div>
      </div>
      <LogOutModal
        modalState={logOutModal}
        closeModal={() =>
          dispatch(setAuthPopUpValues({ key: "logOutModal", value: false }))
        }
        logOut={handleLogout}
        isLoading={logOutLoading}
      />
    </>
  );
};

export default Layout;
