// import * as React from "react";

import {
  AccessTime,
  AnalyticsOutlined,
  AssignmentOutlined,
  BorderColorOutlined,
  CheckRounded,
  CloseOutlined,
  Delete,
  ExpandMore,
  LockOutlined,
  PaymentsOutlined,
  ScheduleOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import { formatNumber, getDifference } from "../../../utils/TimeFormats";
import { useBlockUnblockStudentMutation, useTopUpStudentBalanceMutation } from "../../../api/studentsApi";
import { LoadingButton } from "@mui/lab";
import jebak from '../../../assets/jebak.svg'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography sx={{ pt: "20px" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function SubscriberInfoDrawer(props) {
  const { open, onClose, drawerData, changeIdx, openDeleteModal } = props;
  const [data, setData] = useState({ name: "hello" });
  // const [deleteModal, setDeleteModal] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [addMentorModal, setAddMentorModal] = useState(false);
  // const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleOpenHideModal = () => setHideModal(true);
  const handleOpenAddMentorModal = () => setAddMentorModal(true);
  const handleClose = () => {
    setHideModal(false);
    setAddMentorModal(false);
  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [blockUnblockStudent, { isLoading: blockUnblockLoading, isSuccess }] =
  useBlockUnblockStudentMutation();
  const [topUpStudentBalance, { isLoading: topUpStudentLoading }] =
  useTopUpStudentBalanceMutation();
  const [wallet, setWallet] = useState("");

  console.log(drawerData)
  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <Box
          sx={{
            width: { xs: "100%", sm: "550px" },
            height: "100vh",
            backgroundColor: "#F4F4F5",
            overflowY: "scroll",

          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "24px",
            }}
          >
            <CloseOutlined
              sx={{ color: "#262626", cursor: "pointer" }}
              onClick={onClose}
            />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#737373",
              }}
            >
              Студент
            </Typography>
          </Box>
          <Divider
            sx={{ backgroundColor: "#E5E7EB", width: "100%", height: "2px" }}
          />
          <Box sx={{padding: "16px 24px"}}>
          <Box
              sx={{
                padding: '10px',
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                mb: "20px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                  }}
                >
                  {drawerData.name} {drawerData.surname}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#737373",
                  }}
                >
                  {drawerData?.city ? drawerData?.city : "---"}
                </Typography>
              </Box>
              <Box sx={{ width: "100px", height: "100px" }}>
                {drawerData.photo ? (
                  <img
                    src={`${process.env.REACT_APP_ASSTES_URL}storage/${drawerData.photo}`}
                    alt=""
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "100px",
                      bgcolor: "#D1D5DB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "36px",
                        color: "#525252",
                      }}
                    >
                      {drawerData.name?.charAt(0) +
                        "" +
                        drawerData.surname?.charAt(0)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ padding: "8px 8px 16px 8px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  Контакты
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  {drawerData.contact ? drawerData.contact : "-----"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  Дата рождения
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  {drawerData.date_of_birth
                    ? drawerData.date_of_birth
                    : "-----"}
                </Typography>
              </Box>
           
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  Пол
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontSize: "16px",
                  }}
                >
                  {drawerData.gender == "male" ? "Мужской" : "Женской"}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{ backgroundColor: "#E5E7EB", width: "100%", height: "2px" }}
            />
            <Box
              sx={{
                mb: "20px",
                bgcolor: "white",
                padding: "16px",
                borderRadius: "12px",
                mt: "28px",
              }}
            >
              <Box
                sx={{
                  paddingBottom: "12px",
                  backgroundColor: '#EFF6FF',
                  padding: '12px 20px 12px 16px',
                  borderRadius: '8px',
                  borderBottom: "1px solid #E2E8F0",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Box sx={{display: 'flex', alignItems: 'center',  gap: '14px' }}>
                  <img src={jebak} />
                <Typography
                  sx={{
                    flexGrow: "1",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "textColor",
                  }}
                >
                   Кошелёк
                </Typography>

                </Box>
                
                <Box
                  sx={{
                    padding: "4px 12px 4px 8px",
                    borderRadius: "4px",
                    bgcolor: "#E0F2FE",
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                  }}
                >
                  <PaymentsOutlined color="primary" fontSize="20px" />
                  <Typography
                    sx={{ fontWeight: "700", fontSize: "20px", color: "main" }}
                  >
                    {formatNumber(Number(drawerData?.wallet || 0))} c
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  mb: "12px",
                }}
              >
                <Typography
                  color={"textColor"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                >
                  Оплата наличными
                </Typography>
              </Box>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await topUpStudentBalance({
                    id: drawerData.id,
                    body: { wallet: wallet },
                  });

                  setWallet("");
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <TextField
                    type="number"
                    variant="outlined"
                    label="Введите сумму"
                    sx={{ flexGrow: "1" }}
                    value={wallet}
                    onChange={(e) => setWallet(e.target.value)}
                  />
                  <LoadingButton
                    loading={topUpStudentLoading}
                    disabled={wallet < 0.01 || wallet > 10000}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    Пополнить
                  </LoadingButton>
                </Box>
              </form>
            </Box>
            <Box sx={{ mt: "28px", mb: "28px" }}>
              <Box
                sx={{
                  padding: "12px 20px",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "8px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <LockOutlined />
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Заблокировать аккаунт
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#737373",
                      }}
                    >
                      Полностью блокирует действия студента
                    </Typography>
                  </Box>
                </Box>
                <Switch
                  defaultChecked={drawerData.is_blocked}
                  disabled={blockUnblockLoading}
                  onChange={async () =>
                    await blockUnblockStudent(drawerData.id)
                  }
                />
              </Box>
              <Accordion
                sx={{ padding: "8px 4px", borderRadius: "12px", mb: "8px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <AnalyticsOutlined />{" "}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Посмотреть активность 1
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ padding: "8px 4px", borderRadius: "12px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMore color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                  >
                    <WorkspacePremiumOutlined />{" "}
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Сертификаты об окончании
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "32px" }}
              >
                Курсы
              </Typography>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ width: "50%" }}
                      label={
                        <p style={{ fontWeight: "500", fontSize: "18px" }}>
                          Активные
                        </p>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ width: "50%" }}
                      label={
                        <p style={{ fontWeight: "500", fontSize: "18px" }}>
                          Завершенные
                        </p>
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {drawerData?.subscription?.length > 0
                    ? drawerData?.subscription?.map((drawerData) => {
                        return (
                          <Box
                            key={drawerData.id}
                            sx={{
                              padding: "16px 20px",
                              borderRadius: "12px",
                              bgcolor: "white",
                              width: "100%",
                              mb: "8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "16px",
                                alignItems: "start",
                                mb: "16px",
                              }}
                            >
                              <img
                                src={
                                  drawerData?.course?.logo?.includes("storage")
                                    ? `${process.env.REACT_APP_ASSTES_URL}${drawerData?.course?.logo}`
                                    : `${process.env.REACT_APP_ASSTES_URL}/storage/${drawerData?.course?.logo}`
                                }
                                style={{ width: "72px", height: "72px" }}
                                alt=""
                              />
                              <Box sx={{ width: "calc(100% - 88px)" }}>
                                <Typography
                                  sx={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    color: "#1F2937",
                                  }}
                                >
                                  {drawerData?.course?.name}
                                </Typography>
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      justifyContent: "start",
                                      mb: "4px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                      }}
                                    >
                                      <AssignmentOutlined
                                        sx={{
                                          fontSize: "14px",
                                          color: "#9CA3AF",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          color: "#9CA3AF",
                                        }}
                                      >
                                        {drawerData?.course?.quantity_lessons ||
                                          0}{" "}
                                        уроков
                                      </Typography>
                                    </Box>
                                    <div
                                      style={{
                                        width: "1px",
                                        height: "16px",
                                        backgroundColor: "#E5E7EB",
                                      }}
                                    ></div>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                      }}
                                    >
                                      <AccessTime
                                        sx={{
                                          fontSize: "14px",
                                          color: "#9CA3AF",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          color: "#9CA3AF",
                                        }}
                                      >
                                        {Math.ceil(
                                          drawerData?.course?.hours_lessons /
                                            3600
                                        )}{" "}
                                        ч
                                      </Typography>
                                    </Box>
                                    <div
                                      style={{
                                        width: "1px",
                                        height: "16px",
                                        backgroundColor: "#E5E7EB",
                                      }}
                                    ></div>
                                    {drawerData?.course?.has_certificate ==
                                    1 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "4px",
                                        }}
                                      >
                                        <WorkspacePremiumOutlined
                                          sx={{
                                            fontSize: "14px",
                                            color: "#9CA3AF",
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            color: "#9CA3AF",
                                          }}
                                        >
                                          Сертификат
                                        </Typography>
                                      </Box>
                                    ) : null}
                                  </Box>
                                  <Typography
                                    sx={{ fontWeight: "400", fontSize: "12px" }}
                                  >
                                    Подписка:{" "}
                                    {getDifference(drawerData?.deleted_at)
                                      .finished ? (
                                      <span
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        закончился
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color:
                                            getDifference(
                                              drawerData?.deleted_at
                                            ).month == 0 &&
                                            getDifference(
                                              drawerData?.deleted_at
                                            ).day < 10
                                              ? "red"
                                              : "#3B82F6",
                                        }}
                                      >
                                        остался{" "}
                                        {getDifference(drawerData?.deleted_at)
                                          .month > 0
                                          ? `${
                                              getDifference(
                                                drawerData?.deleted_at
                                              ).month
                                            } мес`
                                          : null}{" "}
                                        {getDifference(drawerData?.deleted_at)
                                          .day > 0
                                          ? `${
                                              getDifference(
                                                drawerData?.deleted_at
                                              ).day
                                            } дней`
                                          : null}
                                      </span>
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <LinearProgress
                                variant="determinate"
                                color="inherit"
                                sx={{
                                  width: "100%",
                                  height: "4px",
                                  borderRadius: "100px",
                                  backgroundColor: "#E5E7EB",
                                  color: "#6B7280",
                                  mb: "8px",
                                }}
                                value={drawerData?.progress_percentage}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    color: "#262626",
                                  }}
                                >
                                  Пройдено{" "}
                                  {Math.trunc(drawerData?.progress_percentage)}%
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    color: "#262626",
                                  }}
                                >
                                  {drawerData?.completed_lessons}/
                                  {drawerData?.total_lessons}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    : null}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "28px",
                    }}
                  >
                    <ScheduleOutlined
                      sx={{ fontSize: "52px", color: "#737373" }}
                    />
                    <h1
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#737373",
                      }}
                    >
                      У вас нет завершенных курсов
                    </h1>
                  </div>
                </CustomTabPanel>
              </Box>
            </Box>
          </Box>

        </Box>
      </Drawer>
    </>
  );
}