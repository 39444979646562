import React from "react";
import {
  AcUnit,
  CheckCircle,
  HttpsOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { lessonIcons } from "../../Constants/Lessons";

const LessonRowView = ({
  lesson,
  onClick = () => { },
  isOpen,
  isActive = false,
  isComplete,
}) => {
  return (
    <Box
      sx={{
        padding: "8px",
        display: "flex",
        justifyContent: "space-between",
        gap: "8px",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: isActive ? "#EFF6FF" : null,
        transition: "0.25s",
      }}
      className="hoverEffect"
      onClick={isOpen ? onClick : null}
    >
      {isComplete === 1 ? (
        <CheckCircle
          sx={{
            fontSize: "16px",
            color: "#3B82F6",
          }}
        />
      ) : isComplete === 2 ? (
        <RadioButtonUnchecked
          sx={{
            fontSize: "16px",
            color: "#E5E7EB",
          }}
        />
      ) : isComplete === 3 ? (
        <AcUnit
          sx={{
            fontSize: "16px",
            color: "#6B7280",
          }}
        />
      ) : (
        <HttpsOutlined
          sx={{
            fontSize: "16px",
            color: "#6B7280",
          }}
        />
      )}
      <Typography
        // noWrap
        sx={{
          color: "textColor",
          fontWeight: "400",
          fontSize: "12px",
          flexGrow: 1,
        }}
      >
        {lesson?.name}
      </Typography>
      {lessonIcons[lesson?.type]}
    </Box>
  );
};

export default LessonRowView;
