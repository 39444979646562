import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setEditData } from "../reducers/editCourse";

export const balanceApi = createApi({
  reducerPath: "balanceApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Balance", "Activity", "Purchase", "LessonProgress"],
  endpoints: (build) => ({
    getBalance: build.query({
      query: () => ({
        url: `student/balances`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Balance"),
    }),
    getMyPurchases: build.query({
      query: () => ({
        url: `student/my-purchases`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Purchase"),
    }),
    getMyPurchasesById: build.query({
      query: (id) => ({
        url: `student/my-purchasesByCourseId/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Purchase"),
    }),
    buyCourseSub: build.mutation({
      query: ({ courseId, subId }) => ({
        url: `student/balance/withdraw/${courseId}/${subId}`,
        method: "POST",
        redirect: "follow",
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: ["Balance", "Purchase", "LessonProgress"],
    }),
    getCourseTopics: build.query({
      query: ({ id, role }) => ({
        url: `${role}/course/${id}/topics/v1`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) =>
        providesList(result?.data?.topics, "LessonProgress"),
    }),
    getCourseTopicsStudy: build.query({
      query: ({ id, role }) => ({
        url: `${role}/course/${id}/topics/v1`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "LessonProgress"),
    }),
    saveProgress: build.mutation({
      query: (id) => ({
        url: `watched/lesson/${id}/v1`,
        method: "POST",
        redirect: "follow",
        headers: { Accept: "application/json" },
        // responseHandler: (response) =>response.message()
      }),
      invalidatesTags: ["Purchase", "Activity", "LessonProgress"],
    }),
    getCourseProgress: build.query({
      query: (id) => ({
        url: `course/${id}/progress`,
        headers: { Accept: "application/json" },
      }),
    }),
    getMyActivity: build.query({
      query: () => ({
        url: `student/student-activity`,
        headers: { Accept: "application/json" },
      }),
      // providesTags: (result) => providesList(result, "Activity"),
    }),
    getAlifWebCheckoutParams : build.query({
      query: (money) => ({
        url: `alif/web-checkout-params?amount=${money}`,
        headers: { Accept: "application/json" },
      }),
    }),
  }),
});
export const {
  useBuyCourseSubMutation,
  useGetBalanceQuery,
  useLazyGetBalanceQuery,
  useGetMyPurchasesQuery,
  useLazyGetMyPurchasesQuery,
  useGetMyPurchasesByIdQuery,
  useLazyGetMyPurchasesByIdQuery,
  useSaveProgressMutation,
  useGetCourseProgressQuery,
  useGetMyActivityQuery,
  useGetCourseTopicsQuery,
  useGetCourseTopicsStudyQuery,
  useGetAlifWebCheckoutParamsQuery,
} = balanceApi;
