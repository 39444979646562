import { useSelector } from "react-redux";
import { roles } from "../Constants/Roles";

const HaveAccess = ({ validRoles, children, alt }) => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const isValidRole = isAuthenticated
    ? user?.roles?.some((role) => validRoles.includes(roles[role.id]))
    : false;
  return isAuthenticated && isValidRole ? children : alt || null;
};
export default HaveAccess;
