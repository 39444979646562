import { LoadingButton } from "@mui/lab";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

const LogOutModal = ({ modalState, closeModal, isLoading, logOut }) => {
  return (
    <Modal
      open={modalState}
      onClose={closeModal}
      sx={{
        zIndex: "999999999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          padding: "28px 28px 20px 28px",
          borderRadius: "16px",
          backgroundColor: "white",
          outline: "none",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#262626",
            mb: "20px",
          }}
        >
          Баромадан аз профил
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "28px",
            color: "#737373",
            mb: "20px",
          }}
        >
          Шумо мутмаин ҳастед, ки мехоҳед аз система бароед?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <LoadingButton
            onClick={logOut}
            variant="text"
            sx={{ height: "48px", borderRadius: "10px" }}
            loading={isLoading}
          >
            Баромад
          </LoadingButton>
          <Button
            onClick={closeModal}
            variant="text"
            sx={{ height: "48px", borderRadius: "10px" }}
          >
            Бекоркунӣ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogOutModal;
