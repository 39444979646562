import {
    Box,
    Button,
    Drawer,
    Menu,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLayoutPopUPValues } from "../../../Services/Student/Layout/LayoutSlice";
import authTypeImage from "../../../assets/authTypeImage.svg";

const AuthTypeMenu = () => {
//   const { isAuthenticated } = useSelector((store) => store.auth);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const {
    popUps: { authTypeMenu },
  } = useSelector((state) => state.layoutStudent);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeAuthTypeMenu = () => {
    dispatch(setLayoutPopUPValues({ key: "authTypeMenu", value: null }));
  };

  const content = (
    <Box sx={{ paddingBottom: !mdUp ? "56px" : "" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          mb: "20px",
        }}
      >
        <img src={authTypeImage} alt="auth type image" />
        <Typography fontSize={"20px"} fontWeight={"500"}>
          Тарзи даромадро интихоб кунед
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Button
          variant="contained"
          size="medium"
          fullWidth
          onClick={() => {
            navigate("/login");
            closeAuthTypeMenu();
          }}
        >
          Даромад
        </Button>
        <Button
          variant="outlined"
          size="medium"
          fullWidth
          onClick={() => {
            navigate("/register");
            closeAuthTypeMenu();
          }}
        >
          Бақайдгирӣ
        </Button>
      </Box>
    </Box>
  );

  if (!mdUp) {
    return (
      <Drawer
        anchor="bottom"
        open={authTypeMenu}
        onClose={closeAuthTypeMenu}
        PaperProps={{
          sx: {
            backgroundColor: "#F8F9FB",
            // color: "common.white",
            // width: 250,
            height: "70%",
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
            padding: "12px 20px 28px 20px",
          },
        }}
        sx={{ zIndex: "10000g" }}
        // variant="permanent"
      >
        {content}
      </Drawer>
    );
  }
  return (
    <Menu
      // disableScrollLock={true}
      anchorEl={authTypeMenu}
      id="account-menu"
      open={!!authTypeMenu}
      onClose={closeAuthTypeMenu}
      // onClick={closeProfileMenu}
      // disableScrollLock={true}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: { xs: "scroll", sm: "auto" },
          // boxShadow: "none",
          // boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
          boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.28)",
          filter: "none",
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "20px 20px 28px 20px",
          // border: "1px solid #E2E8F0",
          mt: 1.5,
          // "& .MuiAvatar-root": {
          //   width: 32,
          //   height: 32,
          //   ml: -0.5,
          //   mr: 1,
          // },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "white",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            boxShadow: "none",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {content}
    </Menu>
  );
};

export default AuthTypeMenu;
