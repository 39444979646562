import { createApi } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";

export const studentsApi = createApi({
  reducerPath: "studentsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Students", "StudentBalnce"],
  endpoints: (build) => ({
    getStudents: build.query({
      query: ({ page, search, filterByCourse }) => ({
        url: `/admin/students?page=${page}&&search=${search}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "Students"),
    }),
    getStudentsSubscribers: build.query({
      query: ({ page, search, filterByCourse }) => ({
        url: `/admin/students/subscriptions?page=${page}&&search=${search}&&course_filter=${encodeURIComponent(filterByCourse)}`,
      providesTags: (result) => providesList(result?.data, "Students"),
      headers: { Accept: "application/json" },
    }),
      providesTags: (result) => providesList(result?.data, "Students"),
      headers: { Accept: "application/json" },
    }),
    userTick: build.mutation({
      query: ({ user_id, body }) => ({
        url: `admin/toggle-student-called-status/${user_id}`,
        method: "PUT",
        redirect: "follow",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Students"],
    }),
    addUserComment: build.mutation({
      query: ({ body }) => ({
        url: `admin/comments`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["Students"],
    }),
    getStudentBalance: build.query({
      query: (id) => ({
        url: `admin/student-balance/${id}`,
        headers: { Accept: "application/json" },
      }),
      providesTags: (result) => providesList(result?.data, "StudentBalnce"),
    }),
    topUpStudentBalance: build.mutation({
      query: ({ id, body }) => ({
        url: `admin/student-balance/${id}`,
        method: "POST",
        redirect: "follow",
        body: body,
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["StudentBalnce"],
    }),
    blockUnblockStudent: build.mutation({
      query: (id) => ({
        url: `user/${id}/block`,
        method: "PUT",
        redirect: "follow",
      }),
      invalidatesTags: ["Students"],
    }),
    getSuccessfulStudentAdmin: build.query({
      query: () => ({
        url: `admin/excellent-students`,
        headers: { Accept: "application/json" },
      }),
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useGetStudentBalanceQuery,
  useLazyGetStudentsQuery,
  useUserTickMutation,
  useGetStudentsSubscribersQuery,
  useBlockUnblockStudentMutation,
  useTopUpStudentBalanceMutation,
  useAddUserCommentMutation,
  useGetSuccessfulStudentAdminQuery,
} = studentsApi;
