import React, { useState } from "react";
import style from "./StudentSubscriptionCard.module.css";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  AccessTime,
  AssignmentOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import {
  useAddUserCommentMutation,
  useUserTickMutation,
} from "../../../api/studentsApi";
import { useDispatch } from "react-redux";
import { getDifference } from "../../../utils/TimeFormats";
import time from "../../../assets/access_time.svg";
import check from "../../../assets/check_circle.svg";
import cancel from "../../../assets/cancel.svg";
import { axiosRequest, getToken } from "../../../utils/axiosRequest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toast from "react-hot-toast";

const StudetsSubscriptionCard = ({
  userLogo,
  userName,
  userSurname,
  userLocation,
  userContact,
  userData,
  hidden,
  contactType,
  isTick,
  comments,
  user_id,
  commentModal,
  setCommentModal,
  setIdx,
  setIsId,
  elem,
  dateOfPurchase,
  progress,
  endDay,
}) => {
  const [comment, setComment] = useState(comments ? comments : "");
  const dispatch = useDispatch();
  const [userTick, { isLoading, isSuccess, isError }] = useUserTickMutation();
  const [renewal, setRenewal] = useState("");

  const handleTickUpdate = async (user_id) => {
    try {
      const response = await userTick({
        user_id, // замените на актуальный ID пользователя
        body: { tickStatus: true }, // замените на актуальные данные
      }).unwrap();

      console.log("Tick updated successfully", response);
    } catch (error) {
      console.error("Failed to update tick", error);
    }
  };
  const [addUserComment] = useAddUserCommentMutation();
  const handleCommentAdd = async (body) => {
    try {
      const response = await addUserComment({
        body, // замените на актуальные данные
      }).unwrap();

      console.log("Comment updated successfully", response);
    } catch (error) {
      console.error("Failed to update comment", error);
    }
  };
  const [loadings, setLoadings] = useState({
    get: false,
    post: false,
    addCourse: false,
    addStudent:false
  });

  const extendUserSubscription = async (apiData) => {
    try {
      setLoadings((prev) => ({ ...prev, post: true }));
      const { data } = await axiosRequest.post(
        `admin/super-admin/extend-subscription`,
        apiData
      );
      toast.success(data);
      setLoadings((prev) => ({ ...prev, post: false }));
    } catch (error) {
      console.log(error);
      setLoadings((prev) => ({ ...prev, post: false }));
    }
  };

  const [subscriber, setSubscriber] = useState(null)
  const [subscriptionRenewalModal, setSubscriptionRenewalModal] =
  useState(false);
  return (
    <div className={style.card}>
      <div className={style.cardContain}>
        <div className={style.cardMain}>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Box
              sx={{
                minWidth: "50px",
                minHeight: "50px",
                maxWidth: "50px",
                maxHeight: "50px",
                borderRadius: "100px",
                bgcolor: "#D1D5DB",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#525252",
                }}
              >
                {userName?.charAt(0) +
                  "" +
                  (userSurname != null ? userSurname?.charAt(0) : "")}
              </Typography>
            </Box>
            <div className={style.cardMainRight}>
              <h1 sx={{ fontWeight: "16px" }}>
                {userName} {userSurname}
              </h1>
              <Typography sx={{ color: "#A3A3A3" }}>
                Дата покупки:{" "}
                <span style={{ color: "#737373", fontWeight: "500" }}>
                  {dateOfPurchase}
                </span>
              </Typography>
              {hidden ? (
                <p
                  style={{
                    color: "#3B82F6",
                    backgroundColor: "#DBEAFE",
                    textAlign: "center",
                    padding: "5px 30px",
                    paddingLeft: "60px",
                    position: "absolute",
                    right: "-23px",
                    top: "15px",
                    transform: "rotate(35deg)",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Ба наздики!
                </p>
              ) : null}

              <ul>
                <li>
                  <div className={style.iconText}>
                    {/* <AssignmentOutlined
                    sx={{ fontSize: "14px", color: "#525252" }}
                  /> */}
                    {userLocation}

                    {/* {courseLessons || 0} дарс */}
                  </div>
                </li>
                <li>
                  <div className={style.iconText}>
                    {/* <AccessTimeIcon sx={{ fontSize: "14px", color: "#525252" }} /> */}
                    {/* {Math.ceil(courseHours / 3600)} cоат */}
                  </div>
                </li>
              </ul>
            </div>
          </Box>
        </div>
      </div>
      <Box sx={{ paddingTop: "10px" }}>
        <LinearProgress
          variant="determinate"
          color="inherit"
          sx={{
            color:
              endDay >= 10 ?? endDay < 0
                ? "#00BD40"
                : endDay <= 10 && endDay > 0
                ? "#FF5700"
                : "#94A3B8",
            height: "8px",
            borderRadius: "100px",
            width: "100%",
          }}
          value={progress}
        />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
            Пройдено {Math.round(progress)}%
          </Typography>
          <Typography
            sx={{
              color: "#2563EB",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {elem?.subscription?.remaining_days +
              "/" +
              elem?.subscription?.total_lessons}
          </Typography>
        </Box>
      </Box>
      <Box
        key={elem.id}
        sx={{
          padding: "10px",
          borderRadius: "8px",
          bgcolor: "#F8F9FB",
          width: "100%",
          mb: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "16px" }}>
            <img
              src={
                elem?.course?.logo?.includes("storage")
                  ? `${process.env.REACT_APP_ASSTES_URL}${elem?.course?.logo}`
                  : `${process.env.REACT_APP_ASSTES_URL}/storage/${elem?.course?.logo}`
              }
              style={{ width: "44px", height: "44px" }}
              alt=""
            />
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#262626",
              }}
            >
              {elem?.course?.name}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box
              sx={{
                width: "calc(100% - 88px)",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#262626",
                }}
              >
                {elem?.subscription?.price}
              </Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems : 'center'}}>
            <Box
              sx={{
                backgroundColor: "#FFF",
                padding: "4px 8px",
                borderRadius: "100px",
                maxHeight: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gap: "4px",
                  maxHeight: "16px",
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src={
                    endDay >= 10 ?? endDay < 0
                      ? check
                      : endDay <= 10 && endDay > 0
                      ? time
                      : cancel
                  }
                  alt=""
                />
                <Typography
                  sx={{
                    color:
                      endDay >= 10 ?? endDay < 0
                        ? "#00BD40"
                        : endDay <= 10 && endDay > 0
                        ? "#FF5700"
                        : "#94A3B8",
                    fontSize: "14px",
                  }}
                >
                  {endDay > 0 ? `${endDay} дней` : "закончился"}
                </Typography>
           
              </Box>
              
            </Box>
            {getToken()?.user_type != 4 ? (
                              <Tooltip
                                title="Это функция недоступна для вас"
                                placement="bottom"
                              >
                                <IconButton
                                  sx={{padding: '0px'}}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                              sx={{padding: '0px'}}
                                onClick={(e) => {
                                  setSubscriber(elem)
                                  setSubscriptionRenewalModal(true)
                                  e.stopPropagation();
                                }}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )}
            </Box>
      
         
          </Box>
        </Box>
      </Box>
      <Dialog
          open={subscriptionRenewalModal}
          onClose={() => setSubscriptionRenewalModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Продление подписки"}</DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              value={renewal}
              onChange={(e) => setRenewal(e.target.value)}
              id="outlined-multiline-static"
              placeholder="Example : 2024-05-05 18:08:09"
              label="Продлить на"
            />
            <Box>
              <Button
                onClick={() => {
                  extendUserSubscription({deadline_date: renewal, subscription_id: subscriber?.id})
                  setSubscriptionRenewalModal(false);
                }}
              >
                Сохранить
              </Button>
              <Button onClick={() => setSubscriptionRenewalModal(false)}>
                Отмена
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
    </div>
  );
};
export default StudetsSubscriptionCard;
