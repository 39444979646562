import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useGetStudentsSubscribersQuery } from "../../../api/studentsApi";
import SubscriberInfoDrawer from "../../../components/Admin/Users/SubscriberInfoDrawer";
import Loader from "../../../components/Admin/Loader/Loader";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import time from "../../../assets/access_time.svg";
import check from "../../../assets/check_circle.svg";
import cancel from "../../../assets/cancel.svg";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import StudetsSubscriptionCard from "../../../components/Admin/Students/StudentSubscriptionCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { axiosRequest, getToken } from "../../../utils/axiosRequest";
import toast from "react-hot-toast";
import { handleChangeConfirmCode } from "../../../reducers/editUser";
import jebak from "../../../assets/jebak.svg";
import {
  useGetCoursesQuery,
  useLazySearchCourseQuery,
} from "../../../api/coursesApi";
import { useGetCategoriesQuery } from "../../../api/categoriesApi";
// 1 admin
// 2 mentor
// 3.student
// 4.superadmin
const Subscribers = () => {
  const [category, setCategory] = useState("");
  const [loadings, setLoadings] = useState({
    get: false,
    post: false,
    addCourse: false,
    addStudent: false,
  });
  const { data: categories } = useGetCoursesQuery({ role: "admin", page: "" });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { data = {}, isLoading } = useGetStudentsSubscribersQuery({
    page: page,
    search: search,
    filterByCourse: category,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  function calculateRemainingDays(deletedAt) {
    const now = new Date(); // Текущая дата
    const endDate = new Date(deletedAt); // Дата окончания подписки
    const timeDifference = endDate - now;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }
  const [renewal, setRenewal] = useState("");
  const [subscriptionRenewalModal, setSubscriptionRenewalModal] =
    useState(false);

  const [idx, setIdx] = useState(null);
  const [drawerData, setDrawerData] = useState({});
  const [infoDrawer, setInfoDrawer] = useState(false);
  const [tab, setTab] = useState("table");
  const [subscriber, setSubscriber] = useState(null);
  if (isLoading == true) {
    return <Loader />;
  }
  // console.log(getToken()?.user_type)

  const extendUserSubscription = async (apiData) => {
    try {
      setLoadings((prev) => ({ ...prev, post: true }));
      const { data } = await axiosRequest.post(
        `admin/super-admin/extend-subscription`,
        apiData
      );
      toast.success(data);
      setLoadings((prev) => ({ ...prev, post: false }));
    } catch (error) {
      console.log(error);
      setLoadings((prev) => ({ ...prev, post: false }));
    }
  };
  console.log(categories);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>
              Студенты
            </Typography>

            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box
                sx={{
                  padding: "4px",
                  backgroundColor: "#E2E8F0",
                  borderRadius: "10px",
                  height: "fit-content",
                  marginX: "10px",
                  minWidth: "90px",
                }}
              >
                <IconButton
                  onClick={() => setTab("grid")}
                  sx={{
                    color: tab === "table" && "#334155",
                    backgroundColor: tab === "grid" && "#fff",
                  }}
                >
                  <GridViewOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => setTab("table")}
                  sx={{
                    color: tab === "grid" && "#334155",
                    backgroundColor: tab === "table" && "#fff",
                  }}
                >
                  <ViewAgendaOutlinedIcon />
                </IconButton>
              </Box>

              {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setPage(1);
                  setSearch(e.target["searchParam"].value);
                }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="toggle password visibility">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: { xs: "100%", sm: "300px" }, height: "56px" }}
                  id="outlined-basic"
                  label="Поиск"
                  variant="outlined"
                  name="searchParam"
                  // value={search}
                  // onChange={(e) => setSearch(e.target.value)}
                />
              </form> */}
            </Box>
            {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                setPage(1);
                setSearch(e.target["searchParam"].value);
              }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="toggle password visibility">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                //   defaultValue={".mp4 / .mpeg"}
                sx={{
                  width: { xs: "100%", sm: "300px", md: "300px" },
                  height: "56px",
                }}
                id="outlined-basic"
                label="Поиск"
                variant="outlined"
                name="searchParam"
              />
            </form> */}
            {/* <Link to='/admin/courses/addNewCourse'> <Button variant='contained' sx={{height:"48px"}} startIcon={<AddCircleOutline/>}>
        Новый курс
        </Button>
        </Link> */}
          </Box>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: { xs: "100%", sm: "300px" }, height: "56px" }}
              id="outlined-basic"
              label="Поиск"
              variant="outlined"
              name="searchParam"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <TextField
              id="outlined-select-currency"
              select
              sx={{ width: "280px", height: "48px" }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                },
              }}
              label="Категория"
              variant="outlined"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <MenuItem value={""}>Ҳама курсҳо</MenuItem>
              {categories?.data?.length > 0
                ? categories?.data?.map((elem) => {
                    return (
                      <MenuItem key={elem.id} value={elem.name}>
                        {elem.name}
                      </MenuItem>
                    );
                  })
                : null}
            </TextField>
          </Box>

          <Box sx={{ mt: "50px" }}>
            {/* <TableContainer component={Paper}> */}
            {tab == "table" && (
              <Table
                sx={{
                  minWidth: 700,
                  borderRadius: "12px",
                  overflow: "hidden",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>ФИО</TableCell>
                    <TableCell>Дата покупки</TableCell>
                    <TableCell>Курс/подписка</TableCell>
                    <TableCell>Прогресс</TableCell>
                    <TableCell>Срок</TableCell>
                    <TableCell>Цена</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.subscriptions?.length > 0 &&
                    data?.subscriptions?.map((elem, index) => {
                      let endDay = calculateRemainingDays(
                        elem?.subscription?.deleted_at
                      );
                      let dateOfPurchase = formatDate(
                        elem?.subscription?.created_at
                      );
                      let progress =
                        (elem?.subscription?.remaining_days /
                          elem?.subscription?.total_lessons) *
                        100;
                      return (
                        <TableRow
                          key={elem.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            setInfoDrawer(true);
                            setDrawerData(elem);
                            setIdx(1);
                          }}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: "white",
                            "&:hover": {
                              background: "transparent",
                            },
                          }}
                        >
                          <TableCell sx={{ padding: "12px", width: "300px" }}>
                            {elem.photo ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "100px",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    elem.photo
                                      ? `${process.env.REACT_APP_ASSTES_URL}storage/${elem.photo}`
                                      : null
                                  }
                                  alt="userPhoto"
                                />

                                <Box>
                                  <Typography
                                    sx={{
                                      color: "#262626",
                                      fontWeight: "600",
                                      fontSize: "16px",
                                    }}
                                    maxWidth="275px"
                                    noWrap
                                  >
                                    {elem.name} {elem.surname}
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#2563EB" }}
                                    maxWidth="275px"
                                    noWrap
                                  >
                                    {elem.email != null
                                      ? elem.email
                                      : elem.phone}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "100px",
                                    bgcolor: "#D1D5DB",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "#525252",
                                    }}
                                  >
                                    {elem.name?.charAt(0) +
                                      "" +
                                      elem.surname?.charAt(0)}
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography
                                    sx={{
                                      color: "#262626",
                                      fontWeight: "600",
                                      fontSize: "16px",
                                    }}
                                    maxWidth="275px"
                                    noWrap
                                  >
                                    {elem?.name} {elem?.surname}
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#2563EB" }}
                                    maxWidth="275px"
                                    noWrap
                                  >
                                    {elem?.contact}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>{dateOfPurchase}</TableCell>
                          <TableCell>
                            <Typography sx={{ fontWeight: "600" }}>
                              {elem?.subscription?.name}
                            </Typography>{" "}
                            <Typography>
                              {elem.subscription?.duration +
                                " " +
                                elem?.subscription.duration_type}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: 500 }}
                                >
                                  Пройдено {Math.round(progress)}%
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#2563EB",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {elem?.subscription?.remaining_days +
                                    "/" +
                                    elem?.subscription?.total_lessons}
                                </Typography>
                              </Box>
                              <LinearProgress
                                variant="determinate"
                                color="inherit"
                                sx={{
                                  color:
                                    endDay >= 10 ?? endDay < 0
                                      ? "#00BD40"
                                      : endDay <= 10 && endDay > 0
                                      ? "#FF5700"
                                      : "#94A3B8",
                                  height: "8px",
                                  borderRadius: "100px",
                                  width: "100%",
                                }}
                                value={progress}
                              />
                            </Box>
                          </TableCell>
                          {/* <TableCell sx={{color:"#262626",fontWeight:"400",padding:"12px"}}>{Math.trunc(elem.price)} c</TableCell> */}
                          <TableCell>
                            <Box
                              sx={{
                                backgroundColor:
                                  endDay >= 10 ?? endDay < 0
                                    ? "#E5FFEE"
                                    : endDay <= 10 && endDay > 0
                                    ? "#FFF4E6"
                                    : "#F8FAFC",
                                display: "inline-block",
                                padding: "8px 12px",
                                borderRadius: "100px",
                              }}
                            >
                              <Box sx={{ display: "flex", gap: "4px" }}>
                                <img
                                  style={{ display: "block" }}
                                  src={
                                    endDay >= 10 ?? endDay < 0
                                      ? check
                                      : endDay <= 10 && endDay > 0
                                      ? time
                                      : cancel
                                  }
                                  alt=""
                                />
                                <Typography
                                  sx={{
                                    color:
                                      endDay >= 10 ?? endDay < 0
                                        ? "#00BD40"
                                        : endDay <= 10 && endDay > 0
                                        ? "#FF5700"
                                        : "#94A3B8",
                                    fontSize: "14px",
                                  }}
                                >
                                  {endDay > 0 ? `${endDay} дней` : "закончился"}
                                </Typography>
                              </Box>
                            </Box>
                            {}
                          </TableCell>

                          <TableCell
                            sx={{
                              color: "#262626",
                              fontWeight: "400",
                              padding: "12px",
                            }}
                          >
                            {Math.trunc(elem.subscription?.price)} c
                          </TableCell>
                          <TableCell>
                            {getToken()?.user_type != 4 ? (
                              <Tooltip
                                title="Это функция недоступна для вас"
                                placement="bottom"
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  setSubscriber(elem);
                                  setSubscriptionRenewalModal(true);
                                  e.stopPropagation();
                                }}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
            {tab != "table" && (
              <Grid container sx={{ marginTop: "50px" }} spacing={2}>
                {data?.subscriptions?.length > 0 &&
                  data?.subscriptions?.map((elem, index) => {
                    let endDay = calculateRemainingDays(
                      elem?.subscription?.deleted_at
                    );
                    let dateOfPurchase = formatDate(
                      elem?.subscription?.created_at
                    );
                    let progress =
                      (elem?.subscription?.remaining_days /
                        elem?.subscription?.total_lessons) *
                      100;
                    return (
                      <Grid item xl={4} md={4} xs={12} sm={6}>
                        <StudetsSubscriptionCard
                          endDay={endDay}
                          progress={progress}
                          elem={elem}
                          key={elem.id}
                          userLogo={elem.photo}
                          userName={elem?.name}
                          userSurname={elem?.surname}
                          dateOfPurchase={dateOfPurchase}
                          user_id={elem.id}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            )}

            {/* </TableContainer> */}
            {data?.subscriptions?.length == 0 && (
              <Typography sx={{ textAlign: "center", mt: "15px" }}>
                Подписчики не найдены
              </Typography>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "50px" }}
            >
              <Pagination
                count={Math.ceil(data?.meta?.total / data?.meta?.per_page)}
                shape="rounded"
                color="primary"
                onChange={(e, value) => setPage(value)}
              />
            </Box>
          </Box>
          <SubscriberInfoDrawer
            drawerData={drawerData}
            open={infoDrawer}
            onClose={() => {
              setInfoDrawer(false);
            }}
          />
        </Container>
        <Dialog
          open={subscriptionRenewalModal}
          onClose={() => setSubscriptionRenewalModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Продление подписки"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              value={renewal}
              onChange={(e) => setRenewal(e.target.value)}
              id="outlined-multiline-static"
              placeholder="Example : 2024-05-05 18:08:09"
              label="Продлить на"
            />
            <Box>
              <Button
                onClick={() => {
                  extendUserSubscription({
                    deadline_date: renewal,
                    subscription_id: subscriber?.id,
                  });
                  setSubscriptionRenewalModal(false);
                }}
              >
                Сохранить
              </Button>
              <Button onClick={() => setSubscriptionRenewalModal(false)}>
                Отмена
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default Subscribers;