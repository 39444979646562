import { createSlice } from "@reduxjs/toolkit";

const LayoutSlice = createSlice({
  name: "layout",
  initialState: {
    popUps: {
      sidebar: false,
      navbar: false,
      studySidebar: false,
      menuAnchor: null,
      authTypeMenu: null,
      coinMenu: false,
    },
  },
  reducers: {
    setLayoutValues: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setLayoutPopUPValues: (state, action) => {
      const { key, value } = action.payload;
      state.popUps[key] = value;
    },
  },
  extraReducers: (builder) => {},
});

export const { setLayoutValues, setLayoutPopUPValues } = LayoutSlice.actions;

export default LayoutSlice.reducer;
