import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import GlobalBaseQuery, { providesList } from "./api";
import { setLesson } from "../reducers/purchases";

export const lessonsApi = createApi({
  reducerPath: "lessonsApi",
  baseQuery: GlobalBaseQuery,
  tagTypes: ["Lessons"],
  endpoints: (build) => ({
    getTopicLessons: build.query({
      query: ({id,role}) => `${role}/topic/${id}/lessons`,
      providesTags: (result) => providesList(result?.data, "Lessons"),
      headers:{'Accept':'application/json'},
      // async  onQueryStarted(arg, { dispatch, queryFulfilled}) {
      //   const data = await queryFulfilled
      //   const location = window.location.href
      //   if(location?.includes('study')==true){
      //     dispatch(setLesson(data?.data[0]))
      //   }
      // }
    }),
    getTopicLessonsStudy: build.query({
      query: ({id,role}) => `${role}/topic/${id}/lessons`,
      providesTags: (result) => providesList(result?.data, "Lessons"),
      headers:{'Accept':'application/json'},
      // async  onQueryStarted(arg, { dispatch, queryFulfilled}) {
      //   const data = await queryFulfilled
      //   const location = window.location.href
      //   if(location?.includes('study')==true){
      //     dispatch(setLesson(data?.data[0]))
      //   }
      // }
    }),
    getLessonById: build.query({
      query: ({id,role}) =>({
        url:`${role}/lesson/${id}`,
        headers:{'Accept':'application/json'},
      }) ,
      providesTags: (result) => providesList(result?.data, "Lessons"),
      //   async  onQueryStarted(arg, { dispatch, queryFulfilled}) {
      //   const data = await queryFulfilled
      //   const location = window.location.href
      //   if(location?.includes('study')==true){
      //     dispatch(setLesson(data?.data))
      
      //   }
      // },
    }),
    addLesson: build.mutation({
      query: (body) => ({
        url: `lesson`,
        method: `POST`,
        redirect:`follow`,
        body,
        headers:{'Accept':'application/json'}
      }),
      invalidatesTags: ["Lessons"],
    }),
    deleteLesson: build.mutation({
      query: (id) => ({
        url: `lesson/${id}`,
        method: `DELETE`,
        headers:{'Accept':'application/json'},
      }),
      invalidatesTags: ["Lessons"],
    }),
    editLesson: build.mutation({
      query: ({ id, body }) => ({
        url: `lesson/${id}`,
        method: "POST",
        body: body,
        redirect: "follow",
        headers: { 'Accept': 'application/json' }
      }),
      invalidatesTags: ["Lessons"],
    }),
  //   reorderLessons:build.mutation({
  //     query:(body)=>({
  //         url:`update-lesson-order`,
  //         method:"PUT",
  //         body,
  //         redirect:"follow"
  //     }),
  //     invalidatesTags:['Lessons']
  // })
  }),
});

export const {
  useGetTopicLessonsQuery,
  useAddLessonMutation,
  useDeleteLessonMutation,
  useEditLessonMutation,
  useLazyGetLessonByIdQuery,
  useLazyGetTopicLessonsQuery,
  useGetLessonByIdQuery,
  useGetTopicLessonsStudyQuery,
  // useReorderLessonsMutation,
} = lessonsApi;