import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddLessonMutation,
} from "../../../../../../api/Admin/lessonsApi";
import {
  topicsApi,
} from "../../../../../../api/Admin/topicsApi";
import {
  setAddLessonDataValue,
  setLessonsPopUpValues,
  setLessonsValues,
} from "../../../../../../Services/Admin/Course/Topics/Lessons/LessonsSlice";
import CustomFormModal from "../../../../../Independents/CustomFormModal";
import AddDefaultType from "./AddDefaultType";
import AddQuizType from "./AddQuizType";
import AddTextType from "./AddTextType";
import AddThinkExamType from "./AddThinkExamType";
import AddVideoType from "./AddVideoType";

const AddLessonModal = ({ tests, courseName }) => {
  const [addLesson, { isLoading }] = useAddLessonMutation();
  const {
    popUps: { addModal },
    addLessonData: {
      type,
      name,
      cover,
      video,
      thinkExam,
      quiz,
      description,
      topic_category_id,
      topic_id,
      max_point,
    },
  } = useSelector((store) => store.lessonsAdmin);
  const dispatch = useDispatch();

  const handleAddLesson = async () => {
    try {
      const formData = new FormData();
      formData.append("topic_category_id", topic_category_id);
      formData.append("topic_id", topic_id);
      formData.append("name", name);
      formData.append("type", type);
      if (type === "text" || type === "video") {
        formData.append("cover", cover);
      }
      if (type === "quiz" || type === "think_exam_quiz") {
        formData.append("description", description);
      }
      if (type !== "think_exam_quiz") {
        formData.append("max_point", type === "quiz" ? max_point : 0);
      } else {
        let floor_total_marks = Math.floor(JSON.parse(thinkExam).total_marks)
        formData.append("max_point", floor_total_marks);
      }
      formData.append(
        "content",
        type === "text"
          ? description
          : type === "video"
          ? video
          : type === "think_exam_quiz"
          ? thinkExam 
          : quiz
      );
      await addLesson(formData).unwrap();
      dispatch(
        setLessonsValues({
          key: "addLessonData",
          value: {
            topic_id: topic_id,
            topic_category_id: topic_category_id,
            type: "text",
            name: "",
            cover: "",
            video: "",
            quiz: "",
            description: "",
            thinkExam: "",
            max_point: 0,
          },
        })
      );
      dispatch(topicsApi.util.invalidateTags(["Topics"]));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomFormModal
      open={addModal}
      onClose={() => {
        dispatch(
          setLessonsValues({
            key: "addLessonData",
            value: {
              topic_id: "",
              topic_category_id: "",
              type: "text",
              name: "",
              cover: "",
              video: "",
              quiz: "",
              description: "",
            },
          })
        );
        dispatch(setLessonsPopUpValues({ key: "addModal", value: false }));
      }}
      title={"Новый урок"}
      maxWidth="md"
      onSave={handleAddLesson}
      saveLoading={isLoading}
      disableSaveButton={
        (type === "text" &&
          (typeof cover !== "object" || description === "")) ||
        (type === "video" && (video === "" || typeof cover !== "object")) ||
        (type === "quiz" && (quiz === "" || description === "")) ||
        name === ""
      }
    >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={type}
        onChange={(e) =>
          dispatch(
            setAddLessonDataValue({ key: "type", value: e.target.value })
          )
        }
      >
        <FormControlLabel
          value="text"
          control={<Radio />}
          label="Текстовый"
          componentsProps={{ typography: { fontWeight: "500" } }}
        />
        <FormControlLabel
          value="video"
          control={<Radio />}
          label="Видео"
          componentsProps={{ typography: { fontWeight: "500" } }}
        />

        <FormControlLabel
          value="quiz"
          control={<Radio />}
          label="Quiz"
          componentsProps={{ typography: { fontWeight: "500" } }}
        />
        <FormControlLabel
          value="think_exam_quiz"
          control={<Radio />}
          label="Think Exam"
          componentsProps={{ typography: { fontWeight: "500" } }}
        />
      </RadioGroup>
      {type !== "quiz" && type !== "think_exam_quiz" && (
        <>
          <AddDefaultType />
        </>
      )}
      {type === "text" ? (
        <AddTextType />
      ) : type === "video" ? (
        <>
          <AddVideoType />
        </>
      ) : type === "quiz" ? (
        <>
          <AddQuizType />
        </>
      ) : type === "think_exam_quiz" ? (
              <AddThinkExamType tests={tests} courseName={courseName} />
      ) : null}
    </CustomFormModal>
  );
};

export default AddLessonModal;
