import {
  AccountCircleOutlined,
  GridViewOutlined,
  SchoolOutlined
} from "@mui/icons-material";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Typography,
  Container,
  MenuItem,
  Paper,
  useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayoutPopUPValues } from "../../../Services/Student/Layout/LayoutSlice";
import Logo from "../../../assets/Logo_blue.svg";
import bottomNavigationLogo from "../../../assets/active.icon.svg";
import HaveAccess from "../../../utils/HaveAccess";
import AuthTypeMenu from "./AuthTypeMenu";
import ProfileMenu from "./ProfileMenu";
import CoinMenu from "./CoinMenu";
import Gift from "../../../assets/gift.svg"
import { useGetBalanceQuery } from "../../../api/balanceApi";

const Navbar = () => {
  const { user, isAuthenticated } = useSelector((store) => store.auth);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));;
  const { pathname } = useLocation();
  const [value, setValue] = useState(pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openProfileMenu = (event) => {
    dispatch(
      setLayoutPopUPValues({ key: "menuAnchor", value: event.currentTarget })
    );
  };
  const openCoinMenu = (event) => {
    dispatch(
      setLayoutPopUPValues({ key: "coinMenu", value: event.currentTarget })
    );
  };

  const openAuthTypeMenu = (event) => {
    dispatch(
      setLayoutPopUPValues({ key: "authTypeMenu", value: event.currentTarget })
    );
  };
  const { data: coins } = useGetBalanceQuery(
    {},
    {
      skip: isAuthenticated ? false : true,
    }
  );

  useEffect(() => {
    if (pathname.startsWith("/courses")) {
      setValue("/courses");
    } else if (
      pathname.startsWith("/account") ||
      pathname === "/login" ||
      pathname === "/register"
    ) {
      setValue("/profile");
    } else if (pathname.startsWith('/study')) {
      setValue('/mylearning')
    } else {
      setValue(pathname);
    }
  }, [pathname]);
  const coin = coins?.student_courses_with_coins[0]?.student_course_coin ? coins?.student_courses_with_coins[0]?.student_course_coin : 0
  return (
    <>
      <Box
        sx={{
          py: "12px",
          backgroundColor: "#F8F9FB",
          // backdropFilter: "blur(10px)",
          position: "sticky",
          left: "0",
          top: "0",
          zIndex: "1000",
        }}
      >
        <Container
          sx={{
            maxWidth: "1180px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!mdUp ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box
                sx={{ width: "max-content", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="omuz logo" />
              </Box>
              <HaveAccess validRoles={["student"]}>
                <Box
                  onClick={openCoinMenu}
                  sx={{
                    p: "8px 12px", display: "flex", gap: "4px", bgcolor: "#fff", borderRadius: "8px",
                    cursor: "pointer", alignItems: "center"
                  }} >
                  <img src={Gift} alt="Gift icon" width={"20px"} height={"20px"} />
                  <Typography color="#2563EB" sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px" }} > {coin} б</Typography>
                </Box>
              </HaveAccess>
            </Box>
          ) : null}
          <Box sx={{ display: "flex", gap: "60px" }}>
            {mdUp ? (
              <>
                <Box
                  sx={{ width: "max-content", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  <img src={Logo} alt="omuz logo" />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <HaveAccess validRoles={["student"]}>
                    <MenuItem
                      sx={{
                        padding: "12px 20px",
                        borderRadius: "10px",
                        backgroundColor:
                          pathname === "/mylearning" ? "white" : "transparent",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: pathname === "/mylearning" ? "#2563EB" : "#262626",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        width: "max-content",
                      }}
                      onClick={() => navigate("/mylearning")}
                    >
                      <SchoolOutlined /> Омӯзиши ман
                    </MenuItem>
                  </HaveAccess>
                  <MenuItem
                    sx={{
                      padding: "12px 20px",
                      borderRadius: "10px",
                      backgroundColor:
                        pathname === "/courses" ? "white" : "transparent",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: pathname === "/courses" ? "#2563EB" : "#262626",
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      width: "max-content",
                    }}
                    onClick={() => navigate("/courses")}
                  >
                    <GridViewOutlined /> Курсҳо
                  </MenuItem>
                </Box>
              </>
            ) : null}
          </Box>
          {mdUp ? (
            <HaveAccess
              validRoles={["student"]}
              alt={
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={openAuthTypeMenu}
                >
                  Даромад
                </Button>
              }
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Box
                  onClick={openCoinMenu}
                  sx={{ p: "8px 12px", display: "flex", gap: "4px", bgcolor: "#fff", borderRadius: "8px", cursor: "pointer" }} >
                  <img src={Gift} alt="Gift icon" />
                  <Typography color="#2563EB" sx={{ fontSize: "16px", fontWeight: "600", lineHeight: "24px" }} > {coin} б</Typography>
                </Box>
                <Avatar
                  sx={{
                    bgcolor: "#CBD5E1",
                    borderRadius: "10px",
                    cursor: "pointer",
                    color: "#262626",
                  }}
                  variant="rounded"
                  onClick={openProfileMenu}
                  src={`${process.env.REACT_APP_ASSTES_URL}storage/${user?.photo}`}
                // src={authTypeImage}
                >
                  {user?.name?.charAt(0)}
                  {user?.surname?.charAt(0)}
                </Avatar>
              </Box>
            </HaveAccess>
          ) : null}
        </Container>
      </Box>

      <HaveAccess validRoles={["student"]}>
        <CoinMenu />
      </HaveAccess>
      <HaveAccess validRoles={["student"]}>
        <ProfileMenu />
      </HaveAccess>
      <AuthTypeMenu />
      {!mdUp ? (
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "100000",
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue == "/profile") {
                if (isAuthenticated) {
                  dispatch(
                    setLayoutPopUPValues({ key: "menuAnchor", value: true })
                  );
                  return;
                }
                dispatch(
                  setLayoutPopUPValues({ key: "authTypeMenu", value: true })
                );
                return;
              }
              navigate(`${newValue}`);
              dispatch(
                setLayoutPopUPValues({ key: "menuAnchor", value: null })
              );
              dispatch(
                setLayoutPopUPValues({ key: "authTypeMenu", value: null })
              );
            }}
          >
            <BottomNavigationAction
              label="Omuz"
              value={"/"}
              icon={<img src={bottomNavigationLogo} />}
            />
            {/* <HaveAccess validRoles={["student"]}> */}
            {isAuthenticated ? (
              <BottomNavigationAction
                label="Омӯзиш"
                value={"/mylearning"}
                icon={<SchoolOutlined />}
              />
            ) : null}
            {/* </HaveAccess> */}
            <BottomNavigationAction
              label="Курсҳо"
              value={"/courses"}
              icon={<GridViewOutlined />}
            />
            <BottomNavigationAction
              label="Профил"
              value={"/profile"}
              icon={<AccountCircleOutlined />}
            />
          </BottomNavigation>
        </Paper>
      ) : null}
    </>
  );
};

export default Navbar;
