import { lazy } from "react";


// STUDENT 
// export const Layout = lazy(() => import("../Layout/Student/Layout.jsx"))
export const Home = lazy(() => import("../pages/Student/Home/Home.jsx"))
export const Courses = lazy(() => import('../pages/Student/Courses/Courses.jsx'))
export const MyLearning = lazy(() => import('../pages/Student/MyLearning/MyLearning.jsx'))
export const Notifications = lazy(() => import('../pages/Student/Notifications/Notifications.jsx'))
export const Journal = lazy(() => import('../pages/Student/Courses/Journal/Journal.jsx'))
export const CourseSinglePage = lazy(() => import('../pages/Student/CourseSinglePage/CourseSinglePage.jsx'))
export const Account = lazy(() => import('../pages/Student/Account/Account.jsx'))
export const Profile = lazy(() => import('../pages/Student/Profile/Profile.jsx'))
export const Purchases = lazy(() => import('../pages/Student/Purchases/Purchases.jsx'))
export const Certificates = lazy(() => import('../pages/Student/Certificates/Certificates.jsx'))
export const Settings = lazy(() => import('../pages/Student/Settings/Settings.jsx'))
export const CertificateSinglePage = lazy(() => import('../pages/Student/CertificateSinglePage/CertificateSinglePage.jsx'))
export const Wallet = lazy(() => import('../pages/Student/Wallet/Wallet.jsx'))
export const Study = lazy(() => import('../pages/Student/Study/Study.jsx'))
export const StudyOutlet = lazy(() => import('../pages/Student/StudyOutlet/StudyOutlet.jsx'))
export const Login = lazy(() => import('../pages/Student/Login/Login.jsx'))
export const Register = lazy(() => import('../pages/Student/Register/Register.jsx'))
export const Contacts = lazy(() => import('../pages/Student/Contacts/Contacts.jsx'))
export const ZabonOmuz = lazy(() => import('../pages/Student/ZabonOmuz/ZabonOmuz.jsx'))
export const NotFound = lazy(() => import('../pages/NotFound/NotFound.jsx'))
export const AuthGmail = lazy(() => import('../pages/Student/AuthGmail/AuthGmail.jsx'))

// ADMIN
export const LayoutAdmin = lazy(() => import("../Layout/Admin/Layout.jsx"))
export const DashboardAdmin = lazy(() => import("../pages/Admin/Dashboard/Dashboard.jsx"))
export const CoursesAdmin = lazy(() => import("../pages/Admin/Courses/Courses.jsx"))
export const StudentsApplicationsAdmin = lazy(() => import("../pages/Admin/Courses/Applications/StudentsApplications.jsx"))
export const AddNewCourseAdmin = lazy(() => import("../pages/Admin/Courses/AddNewCourse.jsx"))
export const EditCourseAdmin = lazy(() => import("../pages/Admin/Courses/EditCourse.jsx"))
export const TopicsAdmin = lazy(() => import("../pages/Admin/Courses/Topics/Topics.jsx"))
export const JournalAdmin = lazy(() => import("../pages/Admin/Courses/Journal/Journal.jsx"))
export const StudentJournalPageAdmin = lazy(() => import("../pages/Admin/Courses/Journal/StudentJournalPage.jsx"))
export const SubscriptionsAdmin = lazy(() => import("../pages/Admin/Courses/Subscriptions/Subscriptions.jsx"))
export const SkillsAdmin = lazy(() => import("../pages/Admin/Courses/Skills/Skills.jsx"))
export const StudentsAdmin = lazy(() => import('../pages/Admin/Students/Students.jsx'))
export const MentorsAdmin = lazy(() => import('../pages/Admin/Mentors/Mentors.jsx'))
export const EditMentorAdmin = lazy(() => import('../pages/Admin/Mentors/EditMentor.jsx'))
export const SubscribersAdmin = lazy(() => import('../pages/Admin/Subscribers/Subscribers.jsx'))
export const UsersAdmin = lazy(() => import('../pages/Admin/Users/Users.jsx'))
export const SuperAdmin = lazy(() => import('../pages/Admin/SuperAdmin/SuperAdmin.jsx'))
export const ViewLessonsAdmin = lazy(() => import('../pages/Admin/Courses/Topics/ViewLessons.jsx'))